import React, {lazy} from "react";
import {Navigate} from "react-router-dom";

import Loadable from "src/layouts/shared/loadable/Loadable";
import AuthUserGuard from "./guards/AuthUserGuard";
import GuestGuard from "./guards/GuestGaurd";
import AuthAdminGuard from "./guards/AuthAdminGuard";

// LAYOUT SIDE BAR
const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));

// LAYOUT SIDE BAR
const FullLayoutAdmin = Loadable(lazy(() => import("../layouts/FullLayoutAdmin")));


// LAYOUT SIDE BAR
const BlankLayout = Loadable(lazy(() => import("../layouts/blank/BlankLayout")));

// COMPONENTS
const Dashboard = Loadable(lazy(() => import("../modules/dashboard/Dashboard")));

// AUTH
// account
const Login = Loadable(lazy(() => import("../modules/account/Login")));
// const Register = Loadable(lazy(() => import("../modules/account/Register")));
const ForgotPassword = Loadable(lazy(() => import("../modules/account/ForgotPassword")));
const ForgotPasswordConfirmationMail = Loadable(lazy(() => import("../modules/account/ForgetPasswordMail")));

const AuthConfirmationAccount = Loadable(lazy(() => import("../modules/account/authForms/AuthConfirmationAccount")));


const ProfileSettings = Loadable(lazy(() => import("../modules/account/accountManagement/ProfileSettings")));

const AccountantResetPassword = Loadable(lazy(() => import("../modules/account/authForms/AccountResetPassword")));


const CreationUserByAdmin = Loadable(lazy(() => import("../modules/admin/account/CreateUserByAdmin")));

const AdminDashboard = Loadable(lazy(() => import("../modules/admin/dashboard/DashboardAdmin")));


// const TwoSteps = Loadable(lazy(() => import("../modules/account/TwoSteps")));
// const Error = Loadable(lazy(() => import("../modules/account/Error")));
// const Maintenance = Loadable(
//   lazy(() => import("../modules/account/Maintenance"))
// );
// const ConfirmationEmail = Loadable(
//   lazy(() => import("../modules/account/ConfirmationEmail"))
// );

// const AccountConfirmationProcess = Loadable(
//   lazy(() => import("../modules/account/AccountConfirmationProcess"))
// );


// ADMIN PART
const TestAdmin = Loadable(lazy(() => import("../modules/admin/AdminGuard")));
const UserManagement = Loadable(lazy(() => import("../modules/admin/account/UserManagement")));
const Router = [{
    path: "/",
    element: (<AuthUserGuard>
        <FullLayout/>
    </AuthUserGuard>),
    children: [{path: "/analytics", element: <Dashboard/>}, {
        path: "/profile-settings",
        element: <ProfileSettings/>
    }


        // { path: "/auth/register", element: <Register /> },
        // { path: "/auth/forgot-password", element: <ForgotPassword /> },
        // {
        //   path: "/auth/forgot-password/confirmation",
        //   element: <ForgotPasswordConfirmationMail />,
        // },
        // {
        //  path: "/auth/account/resetPassword",
        //   element: <AccountantResetPassword />,
        // },
    ],
},


    {
        path: "/admin", element: (<AuthAdminGuard>
            <FullLayoutAdmin/>
        </AuthAdminGuard>), children: [{path: "/admin", element: <TestAdmin/>},

            {path: "/admin/profile-settings", element: <ProfileSettings/>},


            {
                path: "/admin/users/management/edit", exact: true, element: <UserManagement/>,
            },


            {
                path: "/admin/dashboard", exact: true, element: <AdminDashboard/>,
            },


            {
                path: "/admin/create/user", exact: true, element: <CreationUserByAdmin/>,
            },


            // { path: "/auth/register", element: <Register /> },
            // { path: "/auth/forgot-password", element: <ForgotPassword /> },
            // {
            //   path: "/auth/forgot-password/confirmation",
            //   element: <ForgotPasswordConfirmationMail />,
            // },
            // {
            //  path: "/auth/account/resetPassword",
            //   element: <AccountantResetPassword />,
            // },
        ],
    },


    {
        path: "/", element: (<GuestGuard>
            <BlankLayout/>
        </GuestGuard>), children: [{path: "/auth/login", element: <Login/>}, // { path: "/auth/register", element: <Register /> },
            {path: "/auth/forgot-password", element: <ForgotPassword/>}, {
                path: "/auth/forgot-password/confirmation", element: <ForgotPasswordConfirmationMail/>,
            },

            {
                path: "/auth/confirmationMailing", element: <AuthConfirmationAccount/>,
            },

            {
                path: "/auth/confirmation", element: <ForgotPasswordConfirmationMail/>,
            },


            {
                path: "/account/resetPassword", element: <AccountantResetPassword/>,
            },


        ],
    },];

export default Router;
